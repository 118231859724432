<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="{span: 20,offset: 2}" :sm="{span: 16, offset: 4}">
        <!-- <div class="top">
          <div class="logo" :style="'background:url('+PlatformLogo+') no-repeat center;backgroundSize: auto 100%;'">
          </div>
          <div class="slogan">{{PlatformTitle}}</div>
        </div>-->
        <div class="main">
          <h2>软件许可及服务协议</h2>
          <h3>
            <strong>【重要须知】</strong>
            <br />
          </h3>
          <p>
            【{{CompanyName}}】（如下简称“{{CompanyAbbreviation}} ”）在此特别提醒用户认真阅读、充分理解本《软件许可及服务协议》（下称“本协议”）。用户应认真阅读、充分理解本协议中各条款，特别涉及免除或者限制{{CompanyAbbreviation}}责任、争议解决和法律适用的条款。免除或者限制责任的条款将以粗体标识，您需要重点阅读。请您审慎阅读并选择接受或不接受本协议（未成年人应在法定监护人陪同下阅读）。您的下载、安装、使用本软件以及账号获取和登录等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
            {{CompanyAbbreviation}}有权修订本协议，更新后的协议条款将公布于官网或软件，自公布之日起生效。用户可重新下载安装本软件或网站查阅最新版协议条款。在{{CompanyAbbreviation}}修改本协议条款后，如果用户不接受修改后的条款，请立即停止使用{{CompanyAbbreviation}}提供的“位置服务软件” 软件和服务，用户继续使用{{CompanyAbbreviation}}提供的“位置服务软件”软件和服务将被视为已接受了修改后的协议。
          </p>
          <h3>一、总则</h3>
          <p>
            1.1.本协议是您（如下也称“用户”）与{{CompanyAbbreviation}}及其运营合作单位（如下简称“合作单位”）之间关于用户下载、安装、使用{{CompanyAbbreviation}}“位置服务软件” 软件（下称“本软件”）以及使用{{CompanyAbbreviation}}相关服务所订立的协议。
            <br />
            1.2.本软件及服务是{{CompanyAbbreviation}}提供的安装在包括但不限于移动智能终端设备上的软件和服务，为使用该智能终端的用户提供官方的驱动程序以及{{CompanyAbbreviation}}的服务等 。
            <br />
            1.3.本软件及服务的所有权和运营权均归{{CompanyAbbreviation}}所有。
          </p>
          <h3>二、软件授权范围</h3>
          <p>
            2.1.{{CompanyAbbreviation}}就本软件给予用户一项个人的、不可转让、不可转授权以及非独占性的许可。
            <br />
            2.2.用户可以为非商业目的在单一台移动终端设备上安装、使用、显示、运行本软件。但用户不得为商业运营目的安装、使用、运行本软件，不可以对本软件或者本软件运行过程中释放到任何终端设备内存中的数据及本软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本软件和相关系统。如果需要进行商业性的销售、复制和散发，例如软件预装和捆绑，必须获得{{CompanyAbbreviation}}的书面授权和许可。
            <br />
            2.3.用户不得未经{{CompanyAbbreviation}}许可，将本软件安装在未经{{CompanyAbbreviation}}明示许可的其他终端设备上。
            <br />2.4.用户可以为使用本软件及服务的目的复制本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。
            <br />
            2.5.除本《协议》明示授权外，{{CompanyAbbreviation}}未授权给用户其他权利，若用户使用其他权利时须另外取得{{CompanyAbbreviation}}的书面同意。
          </p>
          <h3>三、软件的获取、安装、升级</h3>

          <p>
            3.1.用户应当按照{{CompanyAbbreviation}}的指定网站或指定方式下载安装本软件产品。谨防在非指定网站下载本软件，以免移动终端设备感染能破坏用户数据和获取用户隐私信息的恶意程序。如果用户从未经{{CompanyAbbreviation}}授权的第三方获取本软件或与本软件名称相同的安装程序，{{CompanyAbbreviation}}无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
            <br />3.2.用户必须选择与所安装终端设备相匹配的本软件版本，否则，由于软件与设备型号不相匹配所导致的任何软件问题、设备问题或损害，均由用户自行承担。
            <br />
            3.3.为了改善用户体验、完善服务内容，{{CompanyAbbreviation}}有权不时地为您提供本软件替换、修改、升级版本，也有权为替换、修改或升级收取费用，但将收费提前征得您的同意。本软件为用户默认开通“升级提示”功能，视用户使用的软件版本差异，{{CompanyAbbreviation}}提供给用户自行选择是否需要开通此功能。软件新版本发布后，{{CompanyAbbreviation}}不保证旧版本软件的继续可用。
          </p>

          <h3>四、使用规范</h3>
          <p>
            4.1.用户在遵守法律及本《协议》的前提下可依本《协议》使用本软件及服务，用户不得实施如下行为：
            <br />
            <span>4.1.1.删除本软件及其他副本上一切关于版权的信息，以及修改、删除或避开本软件为保护知识产权而设置的技术措施；</span>
            <br />
            <span>4.1.2.对本软件进行反向工程，如反汇编、反编译或者其他试图获得本软件的源代码；</span>
            <br />
            <span>4.1.3.通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span>
            <br />
            <span>4.1.4.使用本软件进行任何危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众网络或者他人操作系统并删除、修改、增加存储信息；未经许可企图探查、扫描、测试本软件的系统或网络的弱点或其它实施破坏网络安全的行为； 企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；</span>
            <br />
            <span>4.1.5.用户通过非{{CompanyAbbreviation}}公司开发、授权或认可的第三方兼容软件、系统登录或使用本软件及服务，或制作、发布、传播上述工具；</span>
            <br />
            <span>4.1.6.未经{{CompanyAbbreviation}}书面同意，用户对软件及其中的信息擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；</span>
            <span>4.1.7.利用本软件发表、传送、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定、公序良俗的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容；</span>
            <br />
            <span>4.1.8.利用本软件发表、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</span>
            <br />
            <span>4.1.9.利用本软件批量发表、传送、传播广告信息及垃圾信息；</span>
            <br />
            <span>4.1.10.其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议许可使用不一致的方式使用本软件和{{CompanyAbbreviation}}提供的其他服务；</span>
            <br />4.2.信息发布规范
            <br />
            <span>
              4.2.1.您可使用本软件发表属于您原创或您有权发表的观点看法、数据、文字、信息、用户名、图片、照片、个人信息、音频、视频文件、链接等信息内容。您必须保证，您拥有您所上传信息内容的知识产权或已获得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。
              <br />
            </span>
            <br />
            <span>4.2.2.您在使用本软件时需遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚及信息真实性等“七条底线”要求。</span>
            <br />4.2.3.您在使用本软件时不得利用本本软件从事以下行为，包括但不限于：
            <br />
            <span>4.2.3.1.制作、复制、发布、传播、储存违反国家法律法规的内容：</span>
            <br />
            <i>
              （1）反对宪法所确定的基本原则的；
              <br />（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
              <br />（3）损害国家荣誉和利益的；
              <br />（4）煽动民族仇恨、民族歧视，破坏民族团结的；
              <br />（5）破坏国家宗教政策，宣扬邪教和封建迷信的；
              <br />（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
              <br />（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
              <br />（8）侮辱或者诽谤他人，侵害他人合法权益的；
              <br />（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
              <br />（10）以非法民间组织名义活动的；
              <br />（11）含有法律、行政法规禁止的其他内容的。
              <br />
            </i>
            <span>4.2.3.2.发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</span>
            <br />
            <span>4.2.3.3.虚构事实、隐瞒真相以误导、欺骗他人；</span>
            <br />
            <span>4.2.3.4.发表、传送、传播广告信息及垃圾信息；</span>
            <br />
            <span>4.2.3.5.从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</span>
            <br />
            4.2.4.未经{{CompanyAbbreviation}}许可，您不得在本软件中进行任何诸如发布广告、销售商品的商业行为。
            <br />4.3.您理解并同意：
            <br />
            <span>4.3.1.{{CompanyAbbreviation}}会对用户是否涉嫌违反上述使用规范做出认定，并根据认定结果中止、终止对您的使用许可或采取其他依本约定可采取的限制措施；</span>
            <br />
            <span>4.3.2.对于用户使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，{{CompanyAbbreviation}}会直接删除；</span>
            <br />
            <span>4.3.3.对于用户违反上述使用规范的行为对第三方造成损害的，您需要以自己的名义独立承担法律责任，并应确保{{CompanyAbbreviation}}免于因此产生损失或增加费用；</span>
            <br />
            <span>4.3.4.若用户违反有关法律规定或协议约定，使{{CompanyAbbreviation}}遭受损失，或受到第三方的索赔，或受到行政管理机关的处罚，用户应当赔偿{{CompanyAbbreviation}}因此造成的损失和（或）发生的费用，包括合理的律师费、调查取证费用。</span>
          </p>
          <h3>五、隐私政策与个人信息保护</h3>
          <p>
            5.1.保护您的个人信息对{{CompanyAbbreviation}}很重要。为了提供软件的功能并改善用户体验，{{CompanyAbbreviation}}将收集如下类型的数据：
            <br /><span>5.1.1.设备相关信息：与您的设备硬件、性能、状态、应用程序等相关的信息。例如系统版本、设备制造商信息、型号名称、设备和主板的识别码、CPU使用情况、安装和使用的应用程序。</span>
            <br /><span>5.1.2.我们指定的与您相关的信息：我们可能收集并使用诸如小米账号等信息。</span>
            <br /><span>5.1.3.网络使用信息：我们可能收集与您使用的{{CompanyAbbreviation}}软件某些功能有关的网络类型、网络信号等。</span>
          </p>

          <h3>六、服务风险及免责声明</h3>
          <p>
            6.1. 用户必须自行配备移动终端设备上网和使用电信增值业务所需的设备，自行负担个人移动终端设备上网或第三方（包括但不限于电信或移动通信提供商）收取的通讯费、信息费等有关费用。如涉及电信增值服务的，我们建议您与您的电信增值服务提供商确认相关的费用问题。
            <br />
            6.2.用户因第三方如通讯线路故障、技术问题、网络、移动终端设备故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，{{CompanyAbbreviation}}及合作单位不承担责任。
            <br />6.3.本软件同大多数互联网软件一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的终端设备信息和数据的安全，继而影响本软件的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。
            <br />
            6.4.因用户使用本软件或要求{{CompanyAbbreviation}}提供特定服务时，本软件可能会调用第三方系统或第三方软件支持用户的使用或访问，使用或访问的结果由该第三方提供，{{CompanyAbbreviation}}不保证通过第三方系统或第三方软件支持实现的结果的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，{{CompanyAbbreviation}}不承担任何责任。
            <br />
            6.5.{{CompanyAbbreviation}}特别提请用户注意，{{CompanyAbbreviation}}为了保障公司业务发展和调整的自主权，{{CompanyAbbreviation}}公司拥有随时修改或中断服务而不需通知用户的权利，{{CompanyAbbreviation}}行使修改或中断服务的权利不需对用户或任何第三方负责。
            <br />
            6.6.除法律法规有明确规定外，我们将尽最大努力确保软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术，用户理解{{CompanyAbbreviation}}不能对此进行担保。
            <br />
            6.7.由于用户因下述任一情况所引起或与此有关的人身伤害或附带的、间接的经济损害赔偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或其他商业损害赔偿或损失，需由用户自行承担：（1）使用或未能使用许可软件；（2）第三方未经许可的使用软件或更改用户的数据；（3）用户使用软件进行的行为产生的费用及损失；（4）用户对软件的误解；（5）非因{{CompanyAbbreviation}}的原因引起的与软件有关的其他损失。
            <br />6.8.用户与其他使用软件的用户之间通过软件进行的行为，因您受误导或欺骗而导致或可能导致的任何人身或经济上的伤害或损失，均由过错方依法承担所有责任。
          </p>
          <h3>七、知识产权声明</h3>
          <p>
            7.1.{{CompanyAbbreviation}}是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音 频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，{{CompanyAbbreviation}}享有上述知识产权。
            <br />
            7.2.未经{{CompanyAbbreviation}}书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权，{{CompanyAbbreviation}}保留追究上述行为法律责任的权利。
          </p>
          <h3>八、协议变更</h3>
          <p>
            8.1.{{CompanyAbbreviation}}公司有权在必要时修改本协议条款，协议条款一旦发生变动，将会在相关页面上公布修改后的协议条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受协议条款的变动。
            <br />
            8.2.{{CompanyAbbreviation}}公司和合作公司有权按需要修改或变更所提供的收费服务、收费标准、收费方式、服务费及服务条款。{{CompanyAbbreviation}}在提供服务时，可能现在或日后对部分服务的用户开始收取一定的费用如用户拒绝支付该等费用，则不能在收费开始后继续使用相关的服务。{{CompanyAbbreviation}}和合作公司将尽最大努力通过电邮或其他方式通知用户有关的修改或变更。
          </p>
          <h3>九、适用法律及争议解决</h3>
          <p>
            9.1.本协议条款之效力和解释均适用中华人民共和国大陆地区的法律。如无相关法律规定的，则参照使用国际商业惯例和/或商业惯例。
            <br />9.2.本协议的签订地是深圳市南山区。
            <br />
            9.3.用户和{{CompanyAbbreviation}}一致同意凡因本服务所产生的纠纷双方应协商解决，协商不成任何一方可提交本协议签订地有管辖权的法院诉讼解决。
          </p>

          <h3>十、其他</h3>
          <p>
            10.1.用户在使用本软件某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议。
            <br />10.2.本协议生效时间为2020年 1月 1日。
            <br />10.3.本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
            <br />10.4.本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "usageProtocol",
  data() {
    return {
      info: "",
      PlatformTitle: "", //平台名
      PlatformLogo: "",
      CompanyName: "深圳市沃达孚科技有限公司",
      CompanyAbbreviation: "沃达孚",
    };
  },
  created() {
    var url = window.location.href
    //   console.log(this.$route.query)
    if (this.$route.query && this.$route.query.company) {
      this.CompanyName = this.$route.query.company;
      this.CompanyAbbreviation = this.$route.query.abbr;
      this.PlatformTitle = this.$route.query.platform;
    } else if (localStorage.getItem("__last_login_info__")) {
      let info = JSON.parse(localStorage.getItem("__last_login_info__"));
      if (info.PlatformTitle === "聚瑞云控GPS监控平台"||info.PlatformTitle ==="聚瑞云控") {
        this.CompanyName = "深圳市聚瑞云控科技有限公司";
        this.CompanyAbbreviation = "聚瑞云控";
      }else if(url.search('tdkj')>0){
        this.CompanyName = "广州通达科技有限公司";
        this.CompanyAbbreviation = "广州通达";
      }else{
        this.CompanyName = "深圳市沃达孚科技有限公司";
        this.CompanyAbbreviation = "沃达孚";
      }
    } else {
      this.CompanyName = "深圳市沃达孚科技有限公司";
      this.CompanyAbbreviation = "沃达孚";
    }
  },
};
</script>

<style lang="scss" scoped>
body {
  width: 100%;
  overflow: hidden;
}
.top {
  width: 100%;
  min-height: 100px;
  .logo {
    width: 200px;
    height: 100px;
    margin-right: 10px;
    float: left;
    background-color: #ccc;
    img {
      display: inline-block;
      width: 100%;
    }
  }
  .slogan {
    float: left;
    font-size: 24px;
    line-height: 100px;
    color: #a1a1a1;
  }
}
.main {
  font-family: "hyqh", "Microsoft YaHei", Verdana, Arial;
  padding: 50px 0;
  width: 100%;
  h2 {
    color: #e60012;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    padding-bottom: 30px;
  }
  h3 {
    font-weight: bold;
    color: #333;
    font-size: 20px;
    padding-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
    color: #666;
    padding-bottom: 20px;
    padding-left: 14px;
    strong {
      display: inline-block;
      padding: 5px;
      font-size: 16px;
    }
    span {
      display: inline-block;
      margin-left: 14px;
    }
    i {
      display: inline-block;
      font-style: normal;
      padding-left: 24px;
    }
  }
}
</style>

